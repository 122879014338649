import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  label: {
    position: 'relative',
    textTransform: 'uppercase',
    marginBottom: '10px',
  },
  formInput: {
    width: '100%',
    marginBottom: '15px',
  },
});

export default useStyles;
