const styles = theme => ({
  formFooter: {
    textAlign: 'left',
  },
  legend: {
    marginBottom: '10px',
    padding: '0 20px',
  },
  approve: {
    marginBottom: '30px',
    padding: '0 20px',
  },
  successMessage: {
    color: theme.palette.success.main,
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  notification: {
    fontSize: '20px',
    lineHeight: 1.6,
  },
  notificationWrapper: {
    height: '20px',
    margin: '10px 0',
  },
  button: {
    width: '100%',
    padding: '0 20px',
    [theme.breakpoints.up('m')]: {
      width: 'auto',
      padding: '0 105px',
    },
  },
  grid: {
    [theme.breakpoints.up('m')]: {
      maxWidth: '50%',
      flexBasis: '50%',
    },
  },
});

export default styles;
