import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Field } from 'react-final-form';

import InputMask from 'react-input-mask';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import theme from '../../styles/theme';

import useStyles from './styles';

const handleChange = (input, onChange) => e => {
  input.onChange(e);

  if (onChange) {
    onChange(e);
  }
};

const FormInput = ({
  label,
  name,
  onChange,
  type,
  mask,
  placeholder,
  ...rest
}) => {
  const classes = useStyles(theme);

  return (
    <Field name={name} {...rest}>
      {({ input, meta }) => {
        return type === 'checkbox' ? (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  {...input}
                  checked={input.value}
                  onChange={handleChange(input, onChange)}
                  type="checkbox"
                  color="primary"
                  className="qaCheckbox"
                />
              }
              label={label}
            />
          </div>
        ) : (
          <div className={classes.formField}>
            <FormControl className={classes.formInput}>
              <InputLabel
                shrink
                htmlFor="bootstrap-input"
                className={classNames(classes.label, 'qaLabel')}
              >
                {label}
              </InputLabel>
              {mask ? (
                <InputMask
                  {...input}
                  mask={mask}
                  onChange={handleChange(input, onChange)}
                >
                  {() => (
                    <TextField
                      variant="outlined"
                      type={type}
                      error={meta.touched && meta.error}
                      helperText={meta.touched && meta.error}
                      inputProps={{ className: 'qaInput' }}
                      placeholder={placeholder}
                      className="qaInputWrapper"
                    />
                  )}
                </InputMask>
              ) : (
                <TextField
                  variant="outlined"
                  {...input}
                  autoComplete="off"
                  onChange={handleChange(input, onChange)}
                  type={type}
                  error={meta.touched && meta.error}
                  helperText={meta.touched && meta.error}
                  inputProps={{ className: 'qaInput' }}
                  placeholder={placeholder}
                  className="qaInputWrapper"
                />
              )}
            </FormControl>
          </div>
        );
      }}
    </Field>
  );
};

FormInput.propTypes = {
  label: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  type: PropTypes.string,
  mask: PropTypes.string,
  placeholder: PropTypes.string,
};

FormInput.defaultProps = {
  onChange: null,
  type: 'text',
  mask: null,
  placeholder: '',
};

export default FormInput;
