import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';

import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import FormInput from '../FormInput';

import {
  required,
  isPhone,
  isEmail,
  isName,
  composeValidators,
} from './validators';
import { queryToObject } from '../../helpers/transformQuery';
import { MetadataContext } from '../../helpers/context';

import styles from './styles';

const SUBMIT_ERROR_TEXT = 'Что-то пошло не так. Попробуйте еще раз';
const SUBMIT_SUCCESS_TEXT =
  'Ваша заявка успешно отправлена. Мы свяжемся с вами в ближайшее время';

class LeadForm extends React.Component {
  state = {
    isNotificationShown: false,
    timeoutId: null,
  };

  static contextType = MetadataContext;

  componentWillUnmount() {
    this.hideNotification();
  }

  hideNotification = hideOnChange => {
    if (hideOnChange && this.state.timeoutId) {
      return false;
    }

    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);
    }

    this.setState({ isNotificationShown: false, timeoutId: null });
  };

  showNotification = (duration = 0) => {
    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);
    }

    return duration
      ? this.setState({
          timeoutId: setTimeout(this.hideNotification, duration),
          isNotificationShown: true,
        })
      : this.setState({
          timeoutId: null,
          isNotificationShown: true,
        });
  };

  /* eslint-disable no-unused-vars */
  onSubmit = async ({ accept, ...values }) => {
    const utmData = Object.values(queryToObject(this.context));

    try {
      const { status } = await fetch(
        typeof window === 'undefined'
          ? `${process.env.LEADS_URL}/api/leads`
          : `${window.LEADS_URL}/api/leads`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...values,
            customer: this.props.customer,
            utm: utmData,
          }),
        }
      );

      if (status >= 200 && status < 300) {
        this.showNotification();
        return false;
      }

      this.showNotification();
      return { [FORM_ERROR]: SUBMIT_ERROR_TEXT };
    } catch (e) {
      this.showNotification();
      return { [FORM_ERROR]: SUBMIT_ERROR_TEXT };
    }
  };
  /* eslint-enable no-unused-vars */

  render() {
    const { classes, buttonId } = this.props;

    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={{}}
        render={({
          submitError,
          handleSubmit,
          form,
          submitting,
          pristine,
          submitSucceeded,
          hasValidationErrors,
          values,
        }) => (
          <form onSubmit={handleSubmit} className="qaForm">
            {!(submitSucceeded && this.state.isNotificationShown) && (
              <>
                <Box mb={[1.5, 3, 3]}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} className={classes.grid}>
                      <FormInput
                        name="name"
                        label={
                          <>
                            ФИО
                            <Typography
                              variant="body2"
                              color="primary"
                              component="span"
                            >
                              *
                            </Typography>
                          </>
                        }
                        validate={composeValidators(
                          required('Вы забыли ввести ФИО'),
                          isName
                        )}
                        placeholder="Ваше ФИО"
                        onChange={() => this.hideNotification(true)}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.grid}>
                      <FormInput
                        name="company"
                        label={
                          <>
                            Компания
                            <Typography
                              variant="body2"
                              color="primary"
                              component="span"
                            >
                              *
                            </Typography>
                          </>
                        }
                        placeholder="Ваша компания"
                        onChange={() => this.hideNotification(true)}
                        validate={required('Вы забыли ввести компанию')}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={[4.5]}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} className={classes.grid}>
                      <FormInput
                        name="email"
                        label={
                          <>
                            Рабочий Email
                            <Typography
                              variant="body2"
                              color="primary"
                              component="span"
                            >
                              *
                            </Typography>
                          </>
                        }
                        type="email"
                        placeholder="Ваш рабочий email"
                        validate={composeValidators(
                          required('Вы забыли ввести email'),
                          isEmail
                        )}
                        onChange={() => this.hideNotification(true)}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.grid}>
                      <FormInput
                        mask="+7 (999) 999-99-99"
                        name="phone"
                        label={
                          <>
                            Номер телефона
                            <Typography
                              variant="body2"
                              color="primary"
                              component="span"
                            >
                              *
                            </Typography>
                          </>
                        }
                        placeholder="+7 (000) 000-00-00"
                        type="tel"
                        validate={composeValidators(
                          required('Вы забыли ввести номер телефона'),
                          isPhone
                        )}
                        onChange={() => this.hideNotification(true)}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={[2]}>
                  <Typography variant="body2">
                    Нажимая кнопку &quot;Отправить заявку&quot;, я принимаю
                    условия{' '}
                    <a
                      href="https://storage.payday.ru/document/confidentiality/latest.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Соглашения об информационном взаимодействии
                      <br />
                    </a>{' '}
                    и выражаю согласие на обработку персональных данных на
                    условиях этого соглашения
                  </Typography>
                </Box>
                <Box className={classes.formFooter}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    size="large"
                    id={
                      !submitting &&
                      !pristine &&
                      !hasValidationErrors &&
                      buttonId
                        ? buttonId
                        : ''
                    }
                    disabled={submitting || pristine || hasValidationErrors}
                    className={classNames(classes.button, 'qaSubmitButton')}
                  >
                    Узнать подробнее
                  </Button>
                </Box>
              </>
            )}

            <div
              className={classNames(
                submitError &&
                  this.state.isNotificationShown &&
                  classes.notificationWrapper
              )}
            >
              {submitError && this.state.isNotificationShown && (
                <div
                  className={classNames(
                    classes.errorMessage,
                    classes.notification
                  )}
                >
                  <span className="qaNotification qaNotificationError">
                    {submitError}
                  </span>
                </div>
              )}
            </div>

            {submitSucceeded && this.state.isNotificationShown && (
              <>
                <div
                  className={classNames(
                    classes.successMessage,
                    classes.notification
                  )}
                >
                  <span className="qaNotification qaNotificationSuccess">
                    {SUBMIT_SUCCESS_TEXT}
                  </span>
                </div>
                <Box mt={(3, 5, 5)}>
                  <Button
                    color="primary"
                    variant="contained"
                    size="large"
                    onClick={() => {
                      this.hideNotification();
                      setTimeout(() => {
                        form.reset();
                        Object.keys(values).forEach(key =>
                          form.resetFieldState(key)
                        );
                      });
                    }}
                    className={classes.button}
                  >
                    Отправить заявку еще раз
                  </Button>
                </Box>
              </>
            )}
          </form>
        )}
      />
    );
  }
}

LeadForm.propTypes = {
  buttonId: PropTypes.string,
  customer: PropTypes.oneOf(['client', 'user']),
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LeadForm);
