// eslint-disable-next-line no-useless-escape
const PHONE_REGEXP = /^(\+7|7|8)[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;
const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NAME_REGEXP = /^[a-zA-Zа-яА-ЯёЁ ,.-]+$/;

export const required = (
  message = 'Поле обязательно для заполнения'
) => value =>
  typeof value === 'undefined' ||
  value === null ||
  (typeof value === 'boolean' && value === false) ||
  (typeof value === 'string' && value.trim() === '')
    ? message
    : undefined;

export const isPhone = value =>
  typeof value !== 'string' || value.length > 32 || !PHONE_REGEXP.test(value)
    ? 'Телефон веден некорректно'
    : undefined;

export const isName = value =>
  typeof value !== 'string' || value.length > 32 || !NAME_REGEXP.test(value)
    ? 'Укажите ваше ФИО'
    : undefined;

export const isEmail = value =>
  typeof value !== 'undefined' &&
  (typeof value !== 'string' ||
    (value.trim() !== '' && !EMAIL_REGEXP.test(value)))
    ? 'Email введен некорректно'
    : undefined;

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);
